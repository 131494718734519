import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../TestimonialsComponent/TestimonialsComponent.css";

function TestimonialsComponent() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    };

    function showSlides(n) {
        const slides = document.getElementsByClassName("testimonial-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "flex";
    };

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    };

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    };

    return (
        <div className="testimonials-component-container">
            <div className="tc-testimonial-div">
                <p className="tc-headline-h1">
                    WHAT OUR CLIENTS SAY
                </p>
                <div className="tc-slideshow">
                    <div className="testimonial-slide">
                        <div className="tc-slide">
                            <div className="tc-slide-icon">
                                <span className="tc-slide-icon-span">&ldquo;</span>
                            </div>
                            <p className="tc-text-p">
                                "Nevin and team helped us manage through what currently is a frustrating and confusing housing market. They offered up to date market data, helped us get home ready to show and supported us through a new and challenging market. Advice was clear data driven and resulted in a sale that worked for all. Highly recommend."
                            </p>
                            <p className="tc-name">&mdash; Seller <span className="tc-review-source"><Link to="https://www.zillow.com/profile/nevinr/#reviews"><img src="/zillow_outline.png" className="tc-review-source-icon" /></Link></span></p>
                        </div>
                        <div className="tc-slide">
                            <div className="tc-slide-icon">
                                <span className="tc-slide-icon-span">&ldquo;</span>
                            </div>
                            <p className="tc-text-p">
                                "My husband and I had the pleasure of working with Nevin and his team for our new home purchase (and prior home sale). The entire team provided exceptional service and navigated us through the complexities of the process with agility and expertise."
                            </p>
                            <p className="tc-name">&mdash; Buyer & Seller <span className="tc-review-source"><Link to="https://www.zillow.com/profile/nevinr/#reviews"><img src="/zillow_outline.png" className="tc-review-source-icon" /></Link></span></p>
                        </div>
                        <div className="tc-slide">
                            <div className="tc-slide-icon">
                                <span className="tc-slide-icon-span">&ldquo;</span>
                            </div>
                            <p className="tc-text-p">
                                "Nevin and his team helped me and my wife buy a home. Nevin is simply the best in this business and worked with us as our friend while being professional, trust-worthy, detail-oriented, patient, and looking out for our best interests."
                            </p>
                            <p className="tc-name">&mdash; Buyer <span className="tc-review-source"><Link to="https://www.google.com/search?q=nevin+real+estate+google+reviews&rlz=1C1_____en-USUS1021US1021&oq=nevin+real&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7Mg0IARAuGK8BGMcBGIAEMgYIAhBFGDkyCAgDEAAYFhgeMggIBBAAGBYYHjIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPdIBCDE0ODZqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x87f62123f0ef4775:0xba21d7b3be8de383,1,,,,"><img src="/google.jpg" className="tc-review-source-icon" /></Link></span></p>
                        </div>
                    </div>

                </div>
                <div className="tc-bottom-div">
                    <Link to="https://www.google.com/search?q=nevin+real+estate+google+reviews&rlz=1C1_____en-USUS1021US1021&oq=nevin+real&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7Mg0IARAuGK8BGMcBGIAEMgYIAhBFGDkyCAgDEAAYFhgeMggIBBAAGBYYHjIGCAUQRRg8MgYIBhBFGDwyBggHEEUYPdIBCDE0ODZqMGoxqAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x87f62123f0ef4775:0xba21d7b3be8de383,1,,,," className="tc-button">Our Google Testimonials</Link>
                    <Link to="https://www.zillow.com/profile/nevinr/#reviews" className="tc-button">Our Zillow Testimonials</Link>
                </div>
            </div>
        </div>
    )
}

export default TestimonialsComponent
