import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "../Navbar/Navbar.css";
import { FaBars, FaTimes, FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaPhone } from 'react-icons/fa';
import { IconContext } from 'react-icons';

function Navbar() {
    const location = useLocation();

    const [backgroundColor, setBackgroundColor] = useState('rgba(255,255,255,0.7)');
    const [textColor, setTextColor] = useState('#243852');
    const [logo, setLogo] = useState('/dark-blue-sbs-logo.png');
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const menuRef = useRef(null);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    useEffect(() => {
        const checkAndSetBackgroundColor = () => {
            const currentPath = location.pathname;
            setBackgroundColor(currentPath === '/' ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)');
        };

        checkAndSetBackgroundColor();
    }, [location]);

    const showButton = () => {
        if (window.innerWidth <= 1200) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    const changeNavbarBackground = () => {
        const scrolled = window.scrollY >= 20;
        setBackgroundColor(scrolled ? 'rgba(36,56,82,1)' : 'rgba(255,255,255,1)');
        setTextColor(scrolled ? '#fff' : '#243852');
        setLogo(scrolled ? '/white-side-by-side-logo.png' : '/dark-blue-sbs-logo.png');
    };

    useEffect(() => {
        showButton();
        const handleResize = () => showButton();
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', changeNavbarBackground);

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setClick(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', changeNavbarBackground);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: textColor }}>
                <div className="navbar" style={{ backgroundColor, color: textColor }}>
                    <div className="navbar-container container" ref={menuRef}>
                        <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                            <img src={logo} alt="Nevin Real Estate" className="logo-img" />
                        </Link>
                        <div className="outer-menu-div">
                            <ul className={window.innerWidth < 1100 ? "small-screen" : "nav-outer-menu"}>
                                <li className="outer-nav-item">
                                    <Link to="https://consumer.hifello.com/lp/665630271184d5002d85ffb5" className="outer-nav-links" style={{ color: textColor }} target="_blank">Track Your Home's Value at The Enclave</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-icon" onClick={handleClick} style={{ color: textColor }}>
                            <FaBars />
                        </div>
                        <ul className={click ? "nav-hamburger-menu active" : "nav-hamburger-menu"} style={{ backgroundColor }}>
                            <li className="close-menu" onClick={closeMobileMenu} style={{ color: textColor }}>Close Menu<FaTimes className="fa-times" /></li>
                            <li className="nav-item">
                                <a href="#top" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#about-the-home" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>Sold: 3201 Butternut Drive</a>
                            </li>
                            <li className="nav-item">
                                <Link to="https://consumer.hifello.com/lp/665630271184d5002d85ffb5" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }} target="_blank">What is my home worth?</Link>
                            </li>
                            <li className="nav-item">
                                <a href="#our-marketing" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>Our Sales Process</a>
                            </li>
                            <li className="nav-item">
                                <a href="#meettheteam" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    Meet the Team
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link to="http://www.nevinrealestate.com/seller-questionnaire" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    Home Seller Questionnaire
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="http://www.nevinrealestate.com" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>Visit NevinRealEstate.com</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="http://www.nevinrealestate.com/contact" className="nav-links" onClick={closeMobileMenu} style={{ color: textColor }}>Contact Us</Link>
                            </li>
                            <li className="dropdown-social-links">
                                <Link to="http://www.facebook.com/nevinrealestate" className="dropdown-social-link" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    <FaFacebook className="dropdown-social-icon" />
                                </Link>
                                <Link to="http://www.instagram.com/nevinrealestate" className="dropdown-social-link" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    <FaInstagram className="dropdown-social-icon" />
                                </Link>
                                <Link to="https://www.linkedin.com/in/nevinraghuveer" className="dropdown-social-link" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    <FaLinkedin className="dropdown-social-icon" />
                                </Link>
                                <a href="tel:952-848-2475" className="dropdown-social-link" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    <FaPhone className="dropdown-social-icon" />
                                </a>
                                <Link to="http://www.nevinrealestate.com/contact" className="dropdown-social-link" onClick={closeMobileMenu} style={{ color: textColor }}>
                                    <FaEnvelope className="dropdown-social-icon" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;
