import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { soldPropertiesData } from "../Home/Data";
import { FaPhone, FaChevronCircleLeft, FaChevronCircleRight, FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaPercent, FaCalendar, FaDollarSign, FaMoneyBillTrendUp, FaChartLine, FaHandshake, FaMoneyBill } from "react-icons/fa";
import "../Home/Home.css";
import TestimonialsComponent from '../../components/TestimonialsComponent/TestimonialsComponent';
import ImageSlider from '../../components/ImageSlider/ImageSlider';

function Home() {
    const [slideIndex, setSlideIndex] = useState(1);
    let slideTimer;

    useEffect(() => {
        showSlides(slideIndex);
        startSlideTimer();
        return () => clearTimeout(slideTimer);
    }, [slideIndex]);

    function plusSlides(n) {
        setSlideIndex(prevIndex => prevIndex + n);
        resetSlideTimer();
    }

    function showSlides(n) {
        const slides = document.getElementsByClassName("sp-sold-slide");
        let i;
        if (slides.length === 0) return;

        if (n > slides.length) { setSlideIndex(1); return; }
        if (n < 1) { setSlideIndex(slides.length); return; }

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slides[slideIndex - 1].style.display = "block";
    }

    function startSlideTimer() {
        slideTimer = setTimeout(() => {
            plusSlides(1);
        }, 7000);
    }

    function resetSlideTimer() {
        clearTimeout(slideTimer);
        startSlideTimer();
    }

    const handlePrev = () => {
        plusSlides(-1);
    }

    const handleNext = () => {
        plusSlides(1);
    }

    const handleCall = () => {
        window.location.href = "tel: +19528482475";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nreteam@nevinrealestate.com";
    };

    const homeAboutAnimations = () => {
        const homeAboutSection = document.querySelector('.home-about-container');
        const homeAboutImgWrapper = document.querySelector('.home-about-img-wrapper');
        const homeAboutTextDiv = document.querySelector('.home-about-text-div');
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY;
        const homeAboutSectionTop = homeAboutSection.getBoundingClientRect().top;

        if (scrollY + windowHeight > homeAboutSectionTop + 200) {
            homeAboutImgWrapper.classList.add('slide-in');
        } else {
            homeAboutImgWrapper.classList.remove('slide-in');
        }

        if (scrollY + windowHeight > homeAboutSectionTop + 100) {
            homeAboutTextDiv.classList.add('slide-down');
        } else {
            homeAboutTextDiv.classList.remove('slide-down');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', homeAboutAnimations);

        return () => {
            window.removeEventListener('scroll', homeAboutAnimations);
        };
    }, []);

    return (
        <>
            <div className="header-tb-alt">
                <p className="sold-pages-top-tb-text">We deliver an exceptional level of service, one that is unprecedented in the real estate industry.</p>
            </div>
            <div className="header-alt">
                <div className="header-alt-img-wrapper">
                    <img src="/enclave-sign-1.png" className="header-alt-img" alt="The Enclave at Medina" /><div className="header-alt-container">
                        <div className="header-alt-contents">
                            <p className="header-alt-headline">
                                The Enclave at Medina Experts
                            </p>
                            <div className="header-alt-button-container">
                                <a href="#about-the-home" className="sold-pages-top-button">Our Most Recent Sale:<br />3201 Butternut Drive</a>
                                <a href="#our-marketing" className="sold-pages-top-button">See Our Sales Approach</a>
                            </div>
                        </div>
                    </div>
                    <div className="header-alt-nevin-img-wrapper">
                        <img src="/nevin-standing.png" className="header-alt-nevin-standing" alt="Nevin Raghuveer" />
                    </div>
                </div>
            </div>
            <div className="header-alt-bottom-bar">
                <p className="sold-pages-top-bb-text">
                    <span className="red bigred">#1</span> at <img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results 9 Years in a Row  |  <span className="red bigred">#1 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results Luxury Agent  |  <span className="red bigred">#49 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Agent in the USA</p>
            </div>
            <div className="header-alt-bottom-bar-md">
                <p className="sold-pages-top-bb-text">
                    <span className="red bigred">#1</span> at <img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results 9 Years in a Row  |  <span className="red bigred">#1 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results Luxury Agent</p>
                <p className="sold-pages-top-bb-text">
                    <span className="red bigred">#49 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Agent in the USA</p>
            </div>
            <div className="header-alt-bottom-bar-sm">
                <p className="sold-pages-top-bb-text">
                    <span className="red bigred">#1</span> at <img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results 9 Years in a Row</p>
                <p className="sold-pages-top-bb-text">
                    <span className="red bigred">#1 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results Luxury Agent</p>
                <p className="sold-pages-top-bb-text">
                    <span className="red bigred">#49 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Agent in the USA</p>
            </div>
            {/* <div className="header">
                <div className="sold-pages-top-img-wrapper" id="top">
                    <img src="/enclave-sign-1.png" className="sold-pages-top-img" alt="The Enclave at Medina" />
                    <div className="sold-pages-top-nevin-img-wrapper">
                        <img src="/nevin-standing.png" className="nevin-standing" alt="Nevin Raghuveer" />
                    </div>
                    <div className="header-top-bar">
                        <p className="sold-pages-top-tb-text">
                            We deliver an exceptional level of service, one that is unprecedented in the real estate industry.
                        </p>
                    </div>
                    <div className="header-bottom-bar">
                        <p className="sold-pages-top-bb-text">
                            <span className="red bigred">#1</span> at <img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results 9 Years in a Row  |  <span className="red bigred">#1 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results Luxury Agent  |  <span className="red bigred">#49 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Agent in the USA</p>
                    </div>
                    <div className="header-bottom-bar-md">
                        <p className="sold-pages-top-bb-text">
                            <span className="red bigred">#1</span> at <img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results 9 Years in a Row  |  <span className="red bigred">#1 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results Luxury Agent</p>
                        <p className="sold-pages-top-bb-text">
                            <span className="red bigred">#49 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Agent in the USA</p>
                    </div>
                    <div className="header-bottom-bar-sm">
                        <p className="sold-pages-top-bb-text">
                            <span className="red bigred">#1</span> at <img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results 9 Years in a Row</p>
                        <p className="sold-pages-top-bb-text">
                            <span className="red bigred">#1 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Results Luxury Agent</p>
                        <p className="sold-pages-top-bb-text">
                            <span className="red bigred">#49 </span><img src="/remax-logo-no-gradient.png" className="remax" alt="RE/MAX" /> Agent in the USA</p>
                    </div>
                </div>
                <div className="sold-pages-top-container">
                    <div className="sold-pages-top-contents">
                        <p className="sold-pages-top-headline">
                            The Enclave at Medina Experts
                        </p>
                        <div className="sold-pages-top-button-container">
                            <a href="#about-the-home" className="sold-pages-top-button">Our Most Recent Sale:<br />3201 Butternut Drive</a>
                            <a href="#our-marketing" className="sold-pages-top-button">See Our Sales Approach</a>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="sold-pages-ath-container">
                <div className="spath-container" id="about-the-home">
                    <p className="sold-pages-just-sold-headline">Just Sold</p>
                </div>
                <div className="home-about-container">
                    <div className="home-about-img-wrapper">
                        <div className="image-slider">
                            <ImageSlider />
                        </div>
                        <img src="/butternut-sold.png" className="home-about-img" alt="3201 Butternut Drive" />
                    </div>
                    <div className="home-about-text-div">
                        <p className="home-about-h1">3201 Butternut Drive</p>
                        <h3 className="home-about-h3">5 bed | 4 Bath | 3,456 SF</h3>
                        {/* <h3 className="home-about-h3">4 Days on Market</h3>
                        <h3 className="home-about-h3">Listed at $779,000</h3> */}
                        <div className="home-about-p-div">
                            <div className="home-about-ul-div">
                                <ul>
                                    <li className="home-about-p">
                                        The Taylor Floorplan
                                    </li>
                                    <li className="home-about-p">
                                        5 Beds & 4 Baths
                                    </li>
                                    <li className="home-about-p">
                                        Original Owners
                                    </li>
                                    <li className="home-about-p">
                                        Finished Lower Level
                                    </li>
                                </ul>
                            </div>
                            <div className="home-about-ul-div">
                                <ul>
                                    <li className="home-about-p">
                                        Upper-Level Loft & Laundry
                                    </li>
                                    <li className="home-about-p">
                                        Sold in 4 Days/Closed in 35 Days
                                    </li>
                                    <li className="home-about-p">
                                        Generated Over 15 Showings in 3 Days
                                    </li>
                                    <li className="home-about-p">
                                        Sold at $220.00/Sq. Ft.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p className="home-about-p">
                            Call us to discuss our pricing strategy and recommendations. This market is different than last year's and to be successful a new pricing strategy must be adopted. With the help of our seller we mapped out a plan and created a successful transaction.
                        </p>
                        <div className="home-about-contact-info">
                            <div className="home-about-contact-info-phone-license">
                                <div className="hacipl-left">
                                    <p className="home-about-label">
                                        Priced at $779,000
                                    </p>
                                </div>
                                <div className="hacipl-right">
                                    <p className="home-about-label">
                                        4 Days on Market
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mtt-button-wrapper">
                            <a href="https://nevinraghuveer.results.net/real-estate/3201-butternut-drive-medina-mn-55340/528395012/" className="mtt-button listing-button" target="_blank">View the Listing</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="market-comparison">
                <div className="mc-outer-container" id="sold">
                    <p className="headline-h1">Market Comparison</p>
                    <div className="mc-container">
                        <div className="mc-left">
                            <p className="mc-name">
                                Nevin
                            </p>
                            <div className="mc-fa-circle">
                                <FaHandshake className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                3
                            </p>
                            <p className="mc-stat-2">
                                Days on Market
                            </p>
                            <p className="mc-source">
                                Median - 2024 YTD
                            </p>
                            <div className="mc-divider"></div>
                            <p className="mc-name">
                                Market
                            </p>
                            <div className="mc-fa-circle">
                                <FaCalendar className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                24
                            </p>
                            <p className="mc-stat-2">
                                Days on Market
                            </p>
                            <p className="mc-source">
                                Median - 2024 YTD
                            </p>
                            <div className="mc-divider-sm"></div>
                        </div>
                        <div className="mc-middle">
                            <p className="mc-name">
                                Nevin
                            </p>
                            <div className="mc-fa-circle">
                                <FaChartLine className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                101.22%
                            </p>
                            <p className="mc-stat-2">
                                List-to-Sale Price Ratio
                            </p>
                            <p className="mc-source">
                                Average - 2024 YTD
                            </p>
                            <div className="mc-divider"></div>
                            <p className="mc-name">
                                Market
                            </p>
                            <div className="mc-fa-circle">
                                <FaPercent className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                99.02%
                            </p>
                            <p className="mc-stat-2">
                                List-to-Sale Price Ratio
                            </p>
                            <p className="mc-source">
                                Average - 2024 YTD
                            </p>
                            <div className="mc-divider-sm"></div>
                        </div>
                        <div className="mc-right">
                            <p className="mc-name">
                                Nevin
                            </p>
                            <div className="mc-fa-circle">
                                <FaMoneyBill className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                $17,600
                            </p>
                            <p className="mc-stat-2">
                                Higher Sale Price
                            </p>
                            <p className="mc-source">
                                Based on $800,000 List Price
                            </p>
                            <div className="mc-divider"></div>
                            <p className="mc-name">
                                Market
                            </p>
                            <div className="mc-fa-circle">
                                <FaDollarSign className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                -$7,840
                            </p>
                            <p className="mc-stat-2">
                                Average Price Change
                            </p>
                            <p className="mc-source">
                                Based on $800,000 List Price
                            </p>
                        </div>
                    </div>
                    <p className="mc-source source-bottom">
                        Source: NorthstarMLS
                    </p>
                </div>
            </div>
            <div className="market-comparison-lg">
                <div className="mc-outer-container" id="sold">
                    <p className="headline-h1">Market Comparison</p>
                    <div className="mc-container-lg-top">
                        <div className="mc-container-lg-name-div">
                            <p className="mc-name-lg">
                                Nevin Real Estate's Stats
                            </p>
                        </div>
                        <div className="mc-container-lg-sections">
                            <div className="mc-lg-nre-left">
                                <div className="mc-fa-circle">
                                    <FaHandshake className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    3
                                </p>
                                <p className="mc-stat-2">
                                    Days on Market
                                </p>
                                <p className="mc-source">
                                    Median - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-nre-middle">
                                <div className="mc-fa-circle">
                                    <FaChartLine className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    101.22%
                                </p>
                                <p className="mc-stat-2">
                                    List-to-Sale Price Ratio
                                </p>
                                <p className="mc-source">
                                    Average - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-nre-right">
                                <div className="mc-fa-circle">
                                    <FaMoneyBill className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    $17,600
                                </p>
                                <p className="mc-stat-2">
                                    Higher Sale Price
                                </p>
                                <p className="mc-source">
                                    Based on $800,000 List Price
                                </p>
                            </div>
                        </div>
                        <div className="mc-divider-lg"></div>
                    </div>
                    <div className="mc-container-lg-bottom">
                        <div className="mc-container-lg-name-div">
                            <p className="mc-name-lg">
                                Local Market Stats
                            </p>
                        </div>
                        <div className="mc-container-lg-sections">
                            <div className="mc-lg-lm-left">
                                <div className="mc-fa-circle">
                                    <FaCalendar className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    24
                                </p>
                                <p className="mc-stat-2">
                                    Days on Market
                                </p>
                                <p className="mc-source">
                                    Median - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-lm-middle">
                                <div className="mc-fa-circle">
                                    <FaPercent className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    99.02%
                                </p>
                                <p className="mc-stat-2">
                                    List-to-Sale Price Ratio
                                </p>
                                <p className="mc-source">
                                    Average - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-lm-right">
                                <div className="mc-fa-circle">
                                    <FaDollarSign className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    -$7,840
                                </p>
                                <p className="mc-stat-2">
                                    Average Price Change
                                </p>
                                <p className="mc-source">
                                    Based on $800,000 List Price
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="mc-source source-bottom">
                        Source: NorthstarMLS
                    </p>
                </div>
            </div>
            <div className="other-sold-properties" id="sold">
                <div className="sp-sold-properties">
                    <p className="headline-h1">Our Previous Enclave Sales</p>
                    <div className="sp-sold-properties-bottom">
                        <div className="sp-sold-properties-map-divs">
                            <div className="sold-property-img-wrapper">
                                <img src="/enclave-map-without-label.png" alt="3295 Butternut Drive" className="sp-sold-property-img" />
                            </div>
                            <div className="sp-sold-properties-map-right-div">
                                <p className="sp-sold-properties-headline-text">
                                    We Know the Enclave at Medina Inside-Out!
                                </p>
                                <p className="sp-map-blurb">
                                    We have been selling The Enclave since Lennar first built it. From the development and floorplans to its amenities, such as the pool, clubhouse, parks and trails, no one knows your neighborhood better than we do. We will sell your home for more!
                                </p>
                                <a href="http://www.nevinrealestate.com/contact" className="previous-sales-button">Schedule A Consultation</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-marketing" id="our-marketing">
                <p className="headline-h1">Selling with Nevin Real Estate</p>
                <div className="om-main-container">
                    <div className="om-main-top">
                        <div className="om-main-top-left">
                            <img src="/marketing-platforms.png" alt="Market Analysis" className="om-main-top-img" />
                            <div className="om-img-overlay-top">
                                <p className="om-img-overlay-text">
                                    Multiplatform Marketing
                                </p>
                            </div>
                        </div>
                        <div className="om-main-top-right">
                            <p className="om-headline">
                                We are your competitive market advantage.
                            </p>
                            <p className="om-blurb">
                                We market each and every listing with the same level of strategy and attention. Our goal is to maximize your home's exposure to help attract the perfect buyer, using industry-leading print and digital marketing strategies.
                            </p>
                            <a href="http://www.nevinrealestate.com/contact" target="_blank" className="om-button">
                                Schedule a Consultation
                            </a>
                        </div>
                    </div>
                    <div className="om-main-bottom">
                        <div className="om-main-bottom-left">
                            <p className="om-headline">
                                Our Services
                            </p>
                            <div className="om-os-row">
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Expert Market Analysis
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Complimentary Staging
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Professional Photography
                                    </p>
                                </div>
                            </div>
                            <div className="om-os-row">
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Custom Property Website
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        3D Virtual Tour
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Multiplatform Marketing
                                    </p>
                                </div>
                            </div>
                            <a href="http://www.nevinrealestate.com/contact" target="_blank" className="om-button">
                                Get Started
                            </a>
                        </div>
                        <div className="om-main-bottom-right">
                            <img src="/home-staging.jpg" alt="Home Staging" className="om-main-bottom-img" />
                            <div className="om-img-overlay bottom-overlay">
                                <p className="om-img-overlay-text">
                                    Home Staging
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="video-div">
                        <video src="/enclave-video.mp4" className="video" alt="Video" controls />
                        <div className="video-div-right">
                            <p className="sp-sold-properties-headline-text">
                                Your Local Enclave at Medina Experts
                            </p>
                            <p className="video-blurb">
                                The Nevin Real Estate team will sell your home for more, in less time than our competitors - all while providing you with an unmatched level of service. We guarantee it!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sold-pages-meet-the-team" id="meettheteam">
                <h1 className="mtt-headline-h1">The Nevin Real Estate Team</h1>
                <div className="sp-mtt-bio-container">
                    <div className="sp-mtt-bio-div">
                        <p className="sp-mtt-bio-p">
                            Whether you're a first-time homebuyer or an experienced investor, our team has proven, successful methods to make your home buying or selling process smooth and stress-free. We will work closely with you to understand your needs and to help you achieve your real estate goals.</p>
                        <p className="sp-mtt-bio-p">
                            Our approach works. Our clients consistently outperform the market, and Nevin's success in real estate continues to earn him several prestigious awards and designations. Combined, our team has over 35 years of real estate experience and more than 1,000 homes sold.
                        </p>
                    </div>
                </div>
                <div className="mtt-left-and-right-divs">
                    <div className="sp-meet-team-links-div">
                        <div className="sp-meet-team-link-wrapper">
                            <div className="sp-meet-team-img-wrapper">
                                <img src="/nevin.png" className="sp-meet-team-img" />
                            </div>
                            <div className="mtt-text-div">
                                <p className="mtt-name">Nevin Raghuveer</p>
                                <p className="mtt-title">REALTOR &reg; | Founder</p>
                                <p className="mtt-bio">
                                    Nevin has over 25 years of experience and consistently ranks as one of the top agents nationwide. His sharp market insight, strong negotiation skills, and dedication to customer service ensure you have a smooth and rewarding home sale.
                                </p>
                                <div className="mtt-button-wrapper">
                                    <a href="https://nevinrealestate.com/about-nevin" className="mtt-button" target='_blank' >Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="sp-meet-team-link-wrapper">
                            <div className="sp-meet-team-img-wrapper">
                                <img src="/elizabeth.png" className="sp-meet-team-img" alt="Elizabeth Mullen" />
                            </div>
                            <div className="mtt-text-div">
                                <p className="mtt-name">Elizabeth Mullen</p>
                                <p className="mtt-title">REALTOR &reg; | Operations Manager</p>
                                <p className="mtt-bio">
                                    With over 15 years of experience in real estate and expertise in all areas of project management to prepare listings for sale, Elizabeth does what it takes to make sure you get the best deal possible.
                                </p>
                                <div className="mtt-button-wrapper">
                                    <a href="https://nevinrealestate.com/about-elizabeth" className="mtt-button" target='_blank' >Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="sp-meet-team-link-wrapper">
                            <div className="sp-meet-team-img-wrapper">
                                <img src="/cassandra.png" className="sp-meet-team-img" alt="Cassandra Bryan" />
                            </div>
                            <div className="mtt-text-div">
                                <p className="mtt-name">Cassandra Bryan</p>
                                <p className="mtt-title">REALTOR &reg; | Marketing Manager</p>
                                <p className="mtt-bio">
                                    Cassandra brings an eye for detail and in-depth tech knowledge to the table. She will market your property via traditional and cutting-edge methods, from data-driven print and digital campaigns to full stack development of custom property websites.
                                </p>
                                <div className="mtt-button-wrapper">
                                    <a href="https://nevinrealestate.com/about-cassandra" className="mtt-button" target='_blank' >Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mtt-awards-div">
                        <p className="sold-pages-h2">AWARDS</p>
                        <div className="sp-mtt-awards-container">
                            <div className="sp-mtt-awards-img-wrap"><img src="/hof.jpg" alt="Hall of Fame" className="mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/mpls-st-paul.jpg" alt="Minneapolis-St. Paul Magazine SuperAgent" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/zillow-awards.jpg" alt="Zillow Awards" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/lifetime.jpg" alt="Lifetime Achievement Award" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/real-trends-1.jpg" alt="America's Best Real Estate Agents - Real Trends" className="sp-mtt-awards-img" /></div>
                        </div>
                        <div className="sp-mtt-awards-text-div">
                            <p className="sp-mtt-awards-text">
                                #1 RE/MAX Results Agent
                            </p>
                            <p className="sp-mtt-awards-text">
                                #2 RE/MAX Agent in the Midwest
                            </p>
                            <p className="sp-mtt-awards-text">
                                #49 RE/MAX Agent in the Country
                            </p>
                            <p className="sp-mtt-awards-text">
                                #1 RE/MAX Luxury Agent in Minnesota
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <TestimonialsComponent />
            <div className="sold-pages-parallax" id="contact">
                <div className="sold-pages-parallax-content">
                    <h1 className="sold-pages-top-headline">Work With Nevin Real Estate</h1>
                    <a href="http://www.nevinrealestate.com/seller-questionnaire" className="sold-pages-parallax-button">Seller Questionnaire</a>
                    <a href="http://www.nevinrealestate.com/contact" className="sold-pages-parallax-button">Schedule A Consultation</a>
                </div>
            </div>
            <div className="sp-footer-container">
                <div className="sp-footer-logo-wrapper">
                    <img src="/white-side-by-side-logo.png" className="sp-footer-logo" alt="Nevin Real Estate Logo" />
                </div>
                <div className="sp-footer-site-link-div">
                    <a href="#top" className="sp-footer-link">Top</a>
                    <a href="#about-the-home" className="sp-footer-link">3201 Butternut Drive</a>
                    <a href="https://consumer.hifello.com/lp/665630271184d5002d85ffb5" className="sp-footer-link" target="_blank">Home Valuation</a>
                    <a href="#our-marketing" className="sp-footer-link">Our Sales Process</a>
                    <a href="#sold" className="sp-footer-link">Market Comparison</a>
                    <a href="#meettheteam" className="sp-footer-link">About Us</a>
                    <a href="http://www.nevinrealestate.com/contact" className="sp-footer-link" target="_blank">Contact</a>
                </div>
                <div className="sp-footer-contact-address-div">
                    <div className="sp-footer-contact-div">
                        <p className="sp-footer-label">Contact</p>
                        <p className="sp-footer-info-bold">Nevin Raghuveer</p>
                        <p className="sp-footer-info">(952) 848-2475</p>
                        <p className="sp-footer-info">NRETeam@<br className="footer-br" />NevinRealEstate.com</p>
                    </div>
                    <div className="sp-footer-address-div">
                        <p className="sp-footer-label">Address</p>
                        <p className="sp-footer-info">3535 Plymouth Boulevard, Suite 210</p>
                        <p className="sp-footer-info">Plymouth, MN 55447</p>
                    </div>
                </div>
                <div className="sp-footer-social-links-div">
                    <div className="sp-footer-social-links">
                        <div className="sp-footer-social-wrapper">
                            <a href="http://www.facebook.com/nevinrealestate" className="sp-footer-social-link">
                                <FaFacebook className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper">
                            <a href="http://www.instagram.com/nevinrealestate" className="sp-footer-social-link">
                                <FaInstagram className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper">
                            <a href="https://www.linkedin.com/in/nevinraghuveer" className="sp-footer-social-link">
                                <FaLinkedin className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper" onClick={handleCall}>
                            <FaPhone className="sp-footer-social-icon" />
                        </div>
                        <div className="sp-footer-social-wrapper" onClick={handleEmail}>
                            <FaEnvelope className="sp-footer-social-icon" />
                        </div>
                    </div>
                </div>
                <div className="sp-footer-legal-and-credentials-div">
                    <div className="sp-footer-credentials-div">
                        <div className="sp-footer-compliance-logos-div">
                            <img src="/white-fair-housing.png" className="sp-footer-compliance-logos" alt="REALTOR, MLS, and Equal Housing Logos" />
                        </div>
                    </div>
                    <div className="sp-footer-legal-div">
                        <p className="sp-footer-legal-p">&copy; 2024 Nevin Real Estate. | <Link to="/privacy" className="footer-privacy-link">Privacy Policy</Link></p>
                        <p className="sp-footer-legal-p">All information is deemed reliable but not guaranteed and should be independently reviewed and verified.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
