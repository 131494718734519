import React, { useEffect } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

import '../ImageSlider/ImageSlider.css';

function ImageSlider() {
    useEffect(() => {
        /*********************
          Thumbnails slider
        *********************/
        // Change the main image whenever a thumbnail button is activated
        $('.thumbnails-slider').on('init', function (e, slider) {
            $(slider.$slides.find('.thumbnail-button')).each(function (index) {
                $(this).on('click', function () {
                    // Move aria-current="true" to this button
                    $(slider.$slides.find('.thumbnail-button').removeAttr('aria-current'));
                    $(this).attr('aria-current', true);

                    // Change the main image to match this thumbnail button
                    var index = $(this).closest('.slick-slide').data('slick-index');
                    $('.main-image-slider').slick('slickGoTo', index);
                });
            });
        });

        // Initialize the slider
        $('.thumbnails-slider').slick({
            vertical: true,
            slidesToShow: 4,
            infinite: false,
            arrows: false,
            instructionsText: 'This carousel contains a column of small thumbnails. Selecting a thumbnail will change the main image in the carousel that follows. Use the buttons to cycle through all the thumbnails, use Enter to select.',
            regionLabel: 'thumbnails carousel'
        });

        /********************
          Main image slider
        *********************/
        $('.main-image-slider').slick({
            slidesToShow: 1,
            draggable: false,
            arrows: true,
            instructionsText: 'This carousel shows one large product image at a time. Use the buttons to move between images, or use the preceding thumbnails carousel to select a specific image to display here.',
            regionLabel: 'main image carousel',
            width: '600px'
        });

        // Update the thumbnail slider when the user changes the main slider directly.
        $('.main-image-slider').on('beforeChange', function (e, slider, currentSlide, nextSlide) {
            // Remove aria-current from the last selected thumbnail image button
            $('.thumbnails-slider .thumbnail-button[aria-current="true"]').removeAttr('aria-current');

            // Select the thumbnail image button that goes with this main image. Most importantly, this updates Slick's internal state to be consistent with the visual change.
            $('.thumbnails-slider').slick('slickGoTo', nextSlide);

            // Add aria-current="true" to the correct thumbnail image button to convey to screen readers that it's active.
            $('.thumbnails-slider .thumbnail-button:eq(' + nextSlide + ')').attr('aria-current', true);
        });
    }, []);

    return (
        <>
            <div className="product-image-carousels">
                {/* <!-- Thumbnail track carousel --> */}
                <div className="thumbnails-slider">
                    <button className="thumbnail-button" aria-current="true">
                        {/* <!-- Note that the alt attribute values describe the FUNCTION of the button, not the CONTENT of the image inside the button. --> */}
                        <img src="/butternut-under-contract-2.png" alt="Go to image 1" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/1.jpg" alt="Go to image 2" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/2.jpg" alt="Go to image 3" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/3.jpg" alt="Go to image 4" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/4.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/5.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/6.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/7.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/8.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/9.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/10.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/11.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/12.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/13.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/14.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/15.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/16.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/17.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/18.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/19.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/butternut/20.jpg" alt="Go to image 5" />
                    </button>
                </div>

                {/* <!-- Main image carousel --> */}
                <div className="main-image-slider">
                    <a href="/butternut-sold.png" target="_blank" className="image-link">
                        <img src="/butternut-under-contract-2.png" alt="Extreme close up of a plant with flat leaves with rounded sides and pointy tips." />
                    </a>

                    <a href="/butternut/1.jpg" target="_blank" className="image-link">
                        <img src="/butternut/1.jpg" alt="Top view of at least 3 plants with flat leaves with rounded sides and pointy tips arranged radially." />
                    </a>

                    <a href="/butternut/2.jpg" target="_blank" className="image-link">
                        <img src="/butternut/2.jpg" alt="Close-up top view of a single plant with flat leaves with rounded sides and pointy tips, showing small serrations along the leaf margins." />
                    </a>

                    <a href="/butternut/3.jpg" target="_blank" className="image-link">
                        <img src="/butternut/3.jpg" alt="Close-up top view of a single plant with flat leaves with rounded sides and pointy tips, showing more pronounced serrations along the leaf margins." />
                    </a>

                    <a href="/butternut/4.jpg" target="_blank" className="image-link">
                        <img src="/butternut/4.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/5.jpg" target="_blank" className="image-link">
                        <img src="/butternut/5.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/6.jpg" target="_blank" className="image-link">
                        <img src="/butternut/6.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/7.jpg" target="_blank" className="image-link">
                        <img src="/butternut/7.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/8.jpg" target="_blank" className="image-link">
                        <img src="/butternut/8.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/9.jpg" target="_blank" className="image-link">
                        <img src="/butternut/9.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/10.jpg" target="_blank" className="image-link">
                        <img src="/butternut/10.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/11.jpg" target="_blank" className="image-link">
                        <img src="/butternut/11.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/12.jpg" target="_blank" className="image-link">
                        <img src="/butternut/12.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/13.jpg" target="_blank" className="image-link">
                        <img src="/butternut/13.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/14.jpg" target="_blank" className="image-link">
                        <img src="/butternut/14.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/15.jpg" target="_blank" className="image-link">
                        <img src="/butternut/15.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/16.jpg" target="_blank" className="image-link">
                        <img src="/butternut/16.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/17.jpg" target="_blank" className="image-link">
                        <img src="/butternut/17.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/18.jpg" target="_blank" className="image-link">
                        <img src="/butternut/18.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/19.jpg" target="_blank" className="image-link">
                        <img src="/butternut/19.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>

                    <a href="/butternut/20.jpg" target="_blank" className="image-link">
                        <img src="/butternut/20.jpg" alt="Close up of a plant showing a group of separate clusters of buds." />
                    </a>
                </div>
            </div>
        </>
    );
}

export default ImageSlider;
